
import { UserEntityModel, UserPwdModel, UserQueryModel, UserSetBaseInfoModel } from '@/entity-model/user-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, put, del } from './request';

const URL_PATH = `${AUTH_BASE_REQUEST_PATH}/user`;
class UserService implements ICRUDQ<UserEntityModel, UserQueryModel> {
    // 组织架构添加成员使用
    private listInOrg: boolean = false;
    public orgId:string = '';
    constructor(listInOrg?: boolean, orgId?:string) {
        if (listInOrg) {
            this.listInOrg = listInOrg;
            this.orgId = orgId;
        }
    }
    async create(model: UserEntityModel):Promise<UserEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<UserEntityModel> {
        const url = `${URL_PATH}/sauth/${modelId}`;
        const res = await get(url);
        return new UserEntityModel().toModel(res);
    }

    async update(model: UserEntityModel):Promise<UserEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: UserEntityModel):Promise<UserEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: UserQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new UserEntityModel().toModel(item));
        return res;
        // 不带分页功能的处理方法
        // return _.map(res, item => item = new UserEntityModel().toModel(item));
    }
    async updateUserInfo(model:UserSetBaseInfoModel):Promise<any> {
        const url = `${URL_PATH}/saveInfo`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }
    async getUserInfoCurrent(model:UserSetBaseInfoModel):Promise<UserSetBaseInfoModel> {
        const url = `${URL_PATH}/user/current`;
        const params = model.toService();
        const res = await get(url, params);
        return new UserSetBaseInfoModel().toModel(res);
    }
    async getUserInfo():Promise<any> {
        const url = `${AUTH_BASE_REQUEST_PATH}/user/sauth/getUserInfo`;
        const res = await get(url);
        return new UserEntityModel().toModel(res);
    }

    async getunLinkUsers(roleId: string):Promise<any> {
        const url = `${AUTH_BASE_REQUEST_PATH}/role/users`;
        const res = await get(url);
        return res;
    }

    async saveLinkUser(roleId: string, userIds: Array<string>):Promise<any> {
        const url = `${AUTH_BASE_REQUEST_PATH}/role/${roleId}/users`;
        const res = await post(url, { roleId, userIds });
        return res;
    }
}

class UserPwdService implements ICRUDQ<UserPwdModel, UserQueryModel> {
    async create(model: UserPwdModel):Promise<UserPwdModel> {
        throw new Error('Not implement.');
    }

    async retrieve(modelId: string):Promise<UserPwdModel> {
        throw new Error('Not implement.');
    }

    async update(model: UserPwdModel):Promise<UserPwdModel> {
        const url = `${URL_PATH}/sauth/resetPasswd`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: UserPwdModel):Promise<UserPwdModel> {
        throw new Error('Not implement.');
    }

    async query(query?: UserQueryModel, page?: number, limit?: number):Promise<any> {
        throw new Error('Not implement.');
    }
}

export default UserService;
export {
    UserPwdService
};
